import React from "react";

import "./OurSpecialty.scss";

const OurSpecialty = (props) => {
	return (
		<div className="our_specialty">
			<div
				id="our-specialty"
				style={{
					position: "absolute",
					top: "-156px",
					opacity: 0,
				}}
			>
				Nothing
			</div>
			<div className="dots_right"></div>
			<div className="dots_left"></div>
			<div className="trucks_bg"></div>
			<div className="df acc ffc content_wrpr">
				<h3 className="ffcb tac">Our Speciality</h3>
				<h4 className="ffcr tac sub_heading">
					Your small to midsize business has very unique needs,
					especially when it comes to industrial and commercial
					properties. We help you get what you need with our
					tried-and-true two-pronged approach:
				</h4>

				<div className="df acsa sec_wrpr communication">
					<div className="cntnt_wrpr">
						<h4 className="ffcblack">Communication is key</h4>

						<p className="ffbm">
							We’re all about communication. Whether you’re a
							tenant-to-be looking for a new warehouse, a landlord
							with a mixed-use building looking for lessees, or a
							growing business looking for more office space, you
							need a broker who will keep you updated on what’s
							happening in the market and how that will affect
							you. Our past clients all agree: At Key, we call you
							before you can call us, and we give you the personal
							service you need.
						</p>

						<button className="red">Learn More</button>
					</div>
					<div className="df img_wrpr">
						<div className="mla img"></div>
					</div>
				</div>

				<div className="df acsa sec_wrpr understanding">
					<div className="df img_wrpr">
						<div className="mra img"></div>
					</div>
					<div className="cntnt_wrpr">
						<h4 className="ffcblack">
							Market understanding is Key
						</h4>

						<p className="ffbm">
							An understanding of the market is what we’re built
							on. We’re local to New york and northern New jersey
							metropolitan area, and we know our neighborhood
							well. We use aggregated data, historical research,
							and live market activity to continuously monitor
							what’s happening on the ground. This combination
							allows us to position and find properties, and match
							them with the right prospects.
						</p>

						<button className="red">Learn More</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OurSpecialty;
