import React from "react";

import "./Header.scss";
import {NavLink} from "react-router-dom";
import {NavHashLink as CuNavLink} from "react-router-hash-link";

const Header = (props) => {
	return (
		<div className="df acsa header">
			<div className="df acsa content_wrpr">
				<div className="logo_wrpr"></div>

				<div className="df acsa mla ffcr navs_wrpr">
					<CuNavLink
						exact
						to="/"
						className="df acsa nav_wrpr"
						activeClassName="selected"
					>
						Home
					</CuNavLink>
					<NavLink
						exact
						to="/services"
						className="df acsa nav_wrpr"
						activeClassName="selected"
					>
						Services
					</NavLink>
					<CuNavLink
						exact
						to="/pos/#our-specialty"
						className="df acsa nav_wrpr"
						activeClassName="selected"
						isActive={() =>
							window.location.hash.indexOf("our-specialty") > -1
						}
					>
						Our Specialty
					</CuNavLink>
					<NavLink
						exact
						to="/about"
						className="df acsa nav_wrpr"
						activeClassName="selected"
					>
						About
					</NavLink>
					<NavLink
						exact
						to="/contact-us"
						className="df acsa nav_wrpr"
						activeClassName="selected"
					>
						Contact Us
					</NavLink>
					<button className="df acc ffcr call_btn">
						Schedule a Call
					</button>
				</div>
			</div>
		</div>
	);
};

export default Header;
