import React from "react";

import "./About.scss";
import ContactForm from "../contact-form/ContactForm";

const About = (props) => {
	return (
		<div className="about_page">
			<div className="df acc banner">
				<h2 className="ttuc ffcblack">About Us</h2>
			</div>

			<div className="lines_wrpr">
				<div className="line">
					<div className="df acsa  content_wrpr">
						<div className="img df acc sec">
							<div className="img_wrpr"></div>
						</div>
						<div className="cntnt sec">
							<h4 className="ffcblack">
								Real Estate, Real Value
							</h4>

							<p className="ffcr">
								At Key realty, we take your commercial property
								personally. Whether you’re looking to sell or
								lease, we’re here guide you along the way to get
								it done.
							</p>
						</div>
					</div>
				</div>

				<div className="line">
					<div className="df acsa  content_wrpr">
						<div className="cntnt sec">
							<h4 className="ffcblack">Mission:</h4>

							<p className="ffcr">
								Every business started as an idea, and grew to
								become what it is today. At Key Commercial
								Realty, we’re passionate about using our
								communication and market data to match
								entrepreneurs to landowners, helping them
								realizing their next needs and dreams.
							</p>
						</div>

						<div className="img df acc sec">
							<div className="img_wrpr"></div>
						</div>
					</div>
				</div>

				<div className="line">
					<div className="df acsa  content_wrpr">
						<div className="img df acc sec">
							<div className="img_wrpr"></div>
						</div>
						<div className="cntnt sec">
							<h3 className="ffcblack">Meet Solomon Braun</h3>
							<h5 className="ffcr">Visionary, leader, friend</h5>

							<p className="ffcr">
								Solomon has been leading Key Commercial Realty
								since it’s inception. He brings over 20+ years
								of real estate experience with him, and he’s
								proud to say he’s helped countless businesses,
								from mom-and-pop shops to multi-million
								establishments, find their next home.
							</p>
							<p className="ffcr" style={{marginTop: "0px"}}>
								<span className="b ffcblack">His secret?</span>{" "}
								Knowing the market well, and communicating
								effectively and regularly with the landlords and
								tenants.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="sub_banner">
				<div className="df acc content_wrpr">
					<p className="ffcb">
						We’re the <span className="ffcblack b">key</span> to
						filling your space!{" "}
					</p>
					<button className="ffcb df acsa cntct_btn">
						Call us today
					</button>
				</div>
			</div>

			<div className="contact_sec_wrpr">
				<div className="df ffc acc content_wrpr">
					<h4 className="ffcb">Contact Us</h4>

					<p className="ffcr tac ttl">
						We believe communicating effectively between tenants and
						landlords is the key to a good transaction. Let’s talk!
					</p>
					<ContactForm></ContactForm>
				</div>
			</div>
		</div>
	);
};

export default About;
