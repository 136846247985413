import React from "react";

import "./Footer.scss";

const Footer = (props) => {
	return (
		<div className="footer">
			<div className="df acc ffc content_wrpr">
				<div className="df acsa cntnt_sec">
					<div className="logo"></div>
					<div className="df mla">
						<div className="sec">
							<p className="ffcr">1405 N. Broad st. Suite 201</p>
							<p className="ffcr">Hillside, NJ 07205</p>
						</div>
						<div className="sec">
							<p className="ffcr">E. Info@KeyCrealty.com</p>
							<p className="ffcr">T. (973) 320-7000</p>
						</div>
						<div className="df acsa socials_wrpr">
							<div className="social_wrpr fb"></div>
							<div className="social_wrpr tw"></div>
							<div className="social_wrpr li"></div>
						</div>
					</div>
				</div>

				<div className="df acsa bottom_sec">
					<div>
						<p className="ffcr">
							2020 Key C. Realty. All Right Researeved
						</p>
					</div>

					<div className="df acsa mla">
						<p className="ffcr btn">Privacy Policy</p>
						<p className="ffcr btn">Terms and Conditions</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
