import React, {useState} from "react";
import {Formik, Field} from "formik";

import "./ContactForm.scss";

const ContactForm = (props) => {
	const [name, updateName] = useState("");
	const [email, updateEmail] = useState("");
	const [phone, updatePhone] = useState("");
	const [org, updateOrg] = useState("");
	const [message, updateMessage] = useState("");
	const [submissionError, updateSubmissionError] = useState(false);
	const [submitting, updateSubmitting] = useState(false);

	const isComplete = () => {
		return name && email && phone && org && message ? true : false;
	};
	const submitForm = () => {
		console.log(isComplete());
		if (isComplete) console.log({name, email, phone, org, message});
		updateMessage("");
		updateOrg("");
		updateName("");
		updatePhone("");
		updateEmail("");
	};

	return (
		<div className="df ffc acc contact_form">
			<div className="df acsa line">
				<div className="inpt_wrpr">
					<label className="ffcr label">Name</label>
					<input
						className="ffcr"
						type="text"
						onChange={(e) => updateName(e.target.value)}
						value={name}
					/>
				</div>

				<div className="inpt_wrpr">
					<label className="ffcr label">Email</label>
					<input
						className="ffcr"
						type="text"
						onChange={(e) => updateEmail(e.target.value)}
						value={email}
					/>
				</div>
			</div>

			<div className="df acsa line">
				<div className="inpt_wrpr">
					<label className="ffcr label">Phone</label>
					<input
						className="ffcr"
						type="text"
						onChange={(e) => updatePhone(e.target.value)}
						value={phone}
					/>
				</div>

				<div className="inpt_wrpr">
					<label className="ffcr label">Company/Organization </label>
					<input
						className="ffcr"
						type="text"
						onChange={(e) => updateOrg(e.target.value)}
						value={org}
					/>
				</div>
			</div>
			<div className="df acsa line">
				<div className="inpt_wrpr textarea">
					<label className="ffcr label">Message</label>
					<textarea
						onChange={(e) => updateMessage(e.target.value)}
						value={message}
					></textarea>
				</div>
			</div>

			{submissionError && <p>Error submitting form</p>}

			<button
				className={`ffcb ${!isComplete() ? "disabled" : ""} ${
					submitting ? "submitting" : ""
				}`}
				onClick={() => {
					updateSubmitting(true);
					if (!isComplete()) return updateSubmissionError(true);

					submitForm();
				}} // disabled={!isComplete()}
			>
				<p>Send Message</p>
			</button>
		</div>
	);
};

export default ContactForm;
