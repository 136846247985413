import React from "react";

import "./WeTakeCommercial.scss";

const WTCP = (props) => {
	return (
		<div className="we_take_cs">
			<div className="df acc ffc content_wrpr">
				<h3 className="ffcb tac">
					At Key, We Take Commercial Property Personally
				</h3>
				<div className="df acsa boxes_wrpr">
					<div className="df acc ffc box_wrpr land">
						<h5 className="ffcr ttuc">Landlords</h5>

						<p className="ffcr tac">
							You’ve got the warehouse, office space, or retail
							space that’s exactly what a business needs. How do
							you find that business?
						</p>
						<button className="red">Learn More</button>
					</div>
					<div className="df acc ffc box_wrpr tenant">
						<h5 className="ffcr ttuc">Tenants</h5>

						<p className="ffcr tac">
							There are dozens of commercial real estate brokers
							out there. How do you get the space you need
							smoothly?
						</p>

						<button className="red">Learn More</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WTCP;
