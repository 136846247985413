import React from "react";

import "./Services.scss";
import ContactForm from "../contact-form/ContactForm";
import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link";

const Services = (props) => {
	return (
		<div className="services_page">
			<div className="df acc banner">
				<h1 className="ffcblack ttuc">Our key services</h1>
			</div>
			<div className="lines_wrpr">
				<div className="df acc ffc content_wrpr">
					<div className="df acsa line">
						<div className="sec df ffc cntnt">
							<div className="ils_wrpr"></div>
							<h3 className="ffcblack">Industrial</h3>

							<p className="ffcr">
								From manufacturing facilities to transportation
								hubs and beyond, Key Commercial Realty will get
								the message about your property out to the right
								clients. Our understanding of the local markets,
								driving factors, and local real estate supply
								&amp; demand helps us position your property in
								the most optimal way.
							</p>

							<HashLink
								to="services/#contact-us"
								className="df acc ffcm button mra cta_btn"
							>
								List my property today
							</HashLink>
						</div>
						<div className="sec df acsa img">
							<div className="mla imgae_wrpr"></div>
						</div>
					</div>

					<div className="df acsa line">
						<div className="sec df acsa img">
							<div className="mra imgae_wrpr"></div>
						</div>
						<div className="sec df ffc cntnt">
							<div className="ils_wrpr"></div>
							<h3 className="ffcblack">Commercial</h3>

							<p className="ffcr">
								Whether you have retail, office, or mixed-use
								space, there’s a business out there that is
								looking for your specific space. Find the right
								prospect to buy or lease your space with the
								most knowledgeable broker, that’s easy to work
								with.
							</p>
							<p className="ffcr" style={{marginTop: "0px"}}>
								We make the process as smooth and streamlined as
								possible with effective communication, and
								through providing you with clear and concise
								market analyses and updates.
							</p>

							<HashLink
								to="services/#contact-us"
								className="df acc ffcm button mra cta_btn"
							>
								Find me the right prospect
							</HashLink>
						</div>
					</div>

					<div className="df acsa line">
						<div className="sec df ffc cntnt">
							<div className="ils_wrpr"></div>
							<h3 className="ffcblack">Warehousing</h3>

							<p className="ffcr">
								From as little as 5,000 Square feet to as large
								as you your desired needs, we’re here to help
								you find the the right space that perfectly
								matches your needs, or the tenant that is
								exactly what you’re looking for. Through
								effective networking and marketing, we’re the
								experts in getting the deal done.
							</p>

							<HashLink
								to="services/#contact-us"
								className="df acc ffcm button mra cta_btn"
							>
								Find me the right prospect
							</HashLink>
						</div>
						<div className="sec df acsa img">
							<div className="mla imgae_wrpr"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="sub_banner">
				<div className="df acc content_wrpr">
					<p className="ffcb">
						We’re the <span className="ffcblack b">key</span> to
						filling your space!{" "}
					</p>
					<button className="ffcb df acsa cntct_btn">
						Call us today
					</button>
				</div>
			</div>
			<div className="contact_sec_wrpr">
				<div
					id="contact-us"
					style={{
						position: "absolute",
						top: "-156px",
						opacity: 0,
					}}
				/>
				<div className="df ffc acc content_wrpr">
					<h4 className="ffcb">Contact Us</h4>

					<p className="ffcr tac text">
						We believe communicating effectively between tenants and
						landlords is the key to a good transaction. Let’s talk!
					</p>
					{/* <div> */}

					<ContactForm></ContactForm>
					{/* </div> */}
				</div>
			</div>
		</div>
	);
};

export default Services;
