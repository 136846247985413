import React from "react";
import logo from "./logo.svg";
import "./App.scss";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Home from "./containers/home/Home";
import Header from "./containers/header/Header";
import Footer from "./containers/footer/Footer";
import ContactPage from "./containers/contact-page/ContactPage";
import Services from "./containers/services/Services";
import About from "./containers/about/About";

import ScrollIntoView from "./containers/ScrollToTop";

function App() {
	return (
		<div className="key_realty">
			<BrowserRouter>
				<ScrollIntoView>
					{console.log("Hello")}
					<Header></Header>
					<Switch>
						<Route
							path="/contact-us"
							component={ContactPage}
						></Route>
						<Route path="/services" component={Services}></Route>
						<Route path="/about" component={About}></Route>
						<Route path="/" component={Home}></Route>
						<Route path="/" component={Home}></Route>
					</Switch>
					<Footer></Footer>
				</ScrollIntoView>
			</BrowserRouter>
		</div>
	);
}

export default App;

// 276 X 92
