import React from "react";

import "./ContactPage.scss";
import ContactForm from "../contact-form/ContactForm";

const ContactPage = (props) => {
	return (
		<div className="contact_page">
			<div className="df acc hdr">
				<h2 className="ffcb">Contact Us</h2>
			</div>

			<div className="df acc ffc content_wrpr">
				<h5 className="ffcr tac">
					We believe communicating effectively between tenants and
					landlords is the key to a good transaction. Let’s talk!
				</h5>

				<div className="df acsa contact_details_wrpr">
					<div className="df acc contact_detail_wrpr">
						<div className="df acc icon"></div>
						<div className="cntnt_wrpr">
							<p className="ffcb lbl">Phone</p>
							<p className="ffcr val">(973) 320-7000</p>
						</div>
					</div>
					<div className="df acc contact_detail_wrpr">
						<div className="df acc icon"></div>{" "}
						<div className="cntnt_wrpr">
							<p className="ffcb lbl">Email</p>
							<p className="ffcr val">Info@KeyCrealty.com</p>
						</div>
					</div>
					<div className="df acc contact_detail_wrpr">
						<div className="df acc icon"></div>{" "}
						<div className="cntnt_wrpr">
							<p className="ffcb lbl">Address</p>
							<p className="ffcr val">
								1405 N. Broad st. Suite 201 Hillside, NJ 07205
							</p>
						</div>
					</div>
				</div>

				<ContactForm></ContactForm>
			</div>
		</div>
	);
};

export default ContactPage;
