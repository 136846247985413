import React from "react";

import "./KeyServices.scss";
import {Link} from "react-router-dom";

const KeyServices = (props) => {
	return (
		<div className="key_services">
			<div className="df acc ffc content_wrpr">
				<h3 className="ffcblack ttuc">Our key services</h3>

				<div className="ffcb tac p">
					<p>
						At Key Commercial Realty, we’ve been helping small and
						midsize companies get space for years.{" "}
					</p>
					<p>
						As the market experts in the greater New York/New Jersey
						metropolitan area, we offer your business the
						information and resources you need to open the door to
						your future.
					</p>
				</div>

				<div className="df acsa cards_wrpr">
					<div className="df ffc acsa card_wrpr ind">
						<h4 className="ffcblack">Industrial</h4>
						<div className="ils_wrpr"></div>
						<p className="ffcb text tac">
							With you from the ground all the way up. Find spaces
							near transportation routes, manufacturing centers,
							or whatever your business needs.
						</p>
						<Link to="/services" className="df acc black button">
							Learn More
						</Link>
					</div>
					<div className="df ffc acsa card_wrpr comm">
						<h4 className="ffcblack">Commercial</h4>
						<div className="ils_wrpr"></div>
						<p className="ffcb text tac">
							Business is booming; you’re ready for a bigger and
							better location. Discover office buildings,
							mixed-use spaces, and more for your business to call
							home
						</p>
						<Link to="/services" className="df acc black button">
							Learn More
						</Link>
					</div>
					<div className="df ffc acsa card_wrpr ware">
						<h4 className="ffcblack">Warehousing</h4>
						<div className="ils_wrpr"></div>
						<p className="ffcb text tac">
							Get more space to call your own. Whether you need
							5,000 or 500,000 Square feet, we help you find it,
							exactly where you need it.
						</p>
						<Link to="/services" className="df acc black button">
							Learn More
						</Link>
					</div>
				</div>

				<Link to="/contact-us" className="df button acc cu_btn">
					Contact us today
				</Link>
			</div>
		</div>
	);
};

export default KeyServices;
