import React from "react";

import "./ContactPage.scss";
import ContactForm from "../../contact-form/ContactForm";

const ContactPage = (props) => {
	return (
		<div className="contact_sec">
			<div className="df acc ffc content_wrpr">
				<h2 className="ffcb">Contact Us</h2>

				<p className="ffcr tac sub_hdr">
					We believe communicating effectively between tenants and
					landlords is the key to a good deal. Let’s talk!
				</p>
				<ContactForm></ContactForm>
			</div>
		</div>
	);
};

export default ContactPage;
