import React from "react";

import "./Testimonial.scss";

const Testimonial = (props) => {
	return (
		<div className="testimonials_wrpr">
			{console.log(":")}
			<div className="df acc ffc content_wrpr">
				<h3 className="ffcb">Testimonials</h3>
				<div className="df acc cards_wrpr">
					<div className="card_wrpr">
						<h5 className="ffcb">Sample Sampleman</h5>
						<p className="ffcr sub_txt">CEO of Space Inc.</p>
						<p className="ffcr cntnt">
							Lorem ipsum dolor sit amet, ne vis stet reque
							posidonium. Sea et regione labitur. Has denique
							accumsan imperdiet in. Te ius clita noster
							expetendis.{" "}
						</p>
					</div>
					<div className="card_wrpr">
						<h5 className="ffcb">Sample Sampleman</h5>
						<p className="ffcr sub_txt">CEO of Space Inc.</p>
						<p className="ffcr cntnt">
							Lorem ipsum dolor sit amet, ne vis stet reque
							posidonium. Sea et regione labitur. Has denique
							accumsan imperdiet in. Te ius clita noster
							expetendis.{" "}
						</p>
					</div>
					<div className="card_wrpr">
						<h5 className="ffcb">Sample Sampleman</h5>
						<p className="ffcr sub_txt">CEO of Space Inc.</p>
						<p className="ffcr cntnt">
							Lorem ipsum dolor sit amet, ne vis stet reque
							posidonium. Sea et regione labitur. Has denique
							accumsan imperdiet in. Te ius clita noster
							expetendis.{" "}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Testimonial;
