import React from "react";

import "./Home.scss";
import KeyServices from "./key-services/KeyServices";
import OurSpecialty from "./our-specialty/OurSpecialty";
import WTCP from "./we-take-commercial/WeTakeCommercial";
import Testimonial from "./testimonial/Testimonial";
import ContactPage from "./contact-sec/ContactPage";
import {Link} from "react-router-dom";

const Home = (props) => {
	return (
		<div className="home_page">
			<div className="banner">
				<div className="df acc ffc content_wrpr">
					<h1 className="ttuc ffcb tac">
						Your Key to business growth
					</h1>
					<h5 className="ffcr tac">
						When you need the space to take your business to the
						next level, Key Commercial Realty opens the door.
					</h5>

					<div className="df acsa btns_wrpr">
						<Link to="/services" className="df acc button red">
							Learn More
						</Link>
						<Link
							to="/contact-us"
							className="df acc button contact"
						>
							Contact Us
						</Link>
					</div>
				</div>
			</div>
			<KeyServices></KeyServices>
			<OurSpecialty></OurSpecialty>
			<WTCP></WTCP>
			<Testimonial></Testimonial>
			<ContactPage></ContactPage>
		</div>
	);
};

export default Home;
